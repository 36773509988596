import { Container, Typography } from '@mui/material';
import React from 'react';

import { isRouteErrorResponse, useRouteError } from "react-router-dom";

function NotFound() {
  const error = useRouteError();
  console.error(error);

  let message = (<i>"Unknown error"</i>);

  if (isRouteErrorResponse(error)) {
    message = <i>{error.statusText}</i>;
  }

  return (
    <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          Oops...
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Apologies, an error has occurred.
        </Typography>
        <Typography variant="body1">{message}</Typography>
    </Container>
  )
}

export default NotFound;