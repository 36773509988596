import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

function CostlyAdventure() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h2" align="center" gutterBottom>Costly Adventure</Typography>
      </Grid>
      <Grid item xs={12}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Phw7orJTREU" title="YouTube video player"
        // @ts-ignore
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Grid>

      <Grid item xs={12}>
        <Typography>Costly Adventure is an indie roguelite Match-3 game from Postperson Studio released October, 10th 2023 on Steam.</Typography>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <Box textAlign='center'>
          <Button target="_blank" rel="noopener" variant="contained" href="https://store.steampowered.com/app/2014480/Costly_Adventure/">Buy it on Steam</Button>
        </Box>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={12}>
        <Typography color="text.secondary">Going on an adventure is a lot of fun but it is also a costly activity. Especially with a troll guarding every single bridge and demanding payment.</Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography color="text.secondary">The further you go, the more expensive it gets. Luckily there are plenty of treasure chests to beat the gold out of.</Typography>
      </Grid>
    </Grid>

  );
}

export default CostlyAdventure;