import React from 'react';
import logo from '../logo.png';
import { Box, Typography } from '@mui/material';

function LandingPage() {
  return (
    <div>
      <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Postperson Studio
        </Typography>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} className="App-logo" alt="Postperson studio logo" />
        </Box>
        <Typography align="center">We are tiny indie game development studio based in Czech Republic.</Typography>
    </div>
  );
}

export default LandingPage;