import React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Container, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import capsule from '../HeaderCapsule.png'

function Games() {
  return (
    <div>
      <Container component="main">
        <Card sx={{width: 460}}>
          <CardMedia sx={{ height: 215 }} image={capsule} title="Costly Adventure header logo" />
          <CardContent>
            <Link component={RouterLink} to='/costly-adventure'>
              <Typography gutterBottom variant="h5" component='div'>
                  Costly Adventure
              </Typography>
            </Link>
            <Typography sx={{ mb: 1.5 }}>
              Indie roguelite Match-3 strategy game released on October 10th, 2023.
            </Typography>
            <Typography color="text.secondary">
              Going on an adventure is a lot of fun but it is also a costly activity. Especially with a troll guarding every single bridge and demanding payment.
            </Typography>
          </CardContent>
          <CardActions>
            <Button target="_blank" rel="noopener" href="https://store.steampowered.com/app/2014480/Costly_Adventure/">But it on Steam</Button>
            <Button target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=Phw7orJTREU">Trailer on Youtube</Button>
          </CardActions>
        </Card>
      </Container>
    </div>
  );
}

export default Games;