import React from 'react';
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

function AboutUs() {
  return (
    <div>
      <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
        >
          About Us
        </Typography>
        <List>
          <ListItem>
            <ListItemText>We strive to make unique games that we would love to play ourselves.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>We take inspiration from modern indie games and games that we have been playing for decades.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>We are based in the heart of Europe in Czech Republic.</ListItemText>
          </ListItem>
        </List>

        <Typography
          component="h2"
          variant="h3"
          align="center">
          Contacts
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon><EmailIcon /></ListItemIcon>
            <ListItemText>Email: <Link href='mailto:website@postpersonstudio.com'>website@postpersonstudio.com</Link></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><VideogameAssetIcon /></ListItemIcon>
            <ListItemText>Steam page: <Link href='https://store.steampowered.com/search/?developer=Postperson%20Studio'>Postperson Studio</Link></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon><YouTubeIcon /></ListItemIcon>
            <ListItemText>Youtube: <Link href='https://www.youtube.com/@PostpersonStudio'>Postperson Studio</Link></ListItemText>
          </ListItem>
        </List>


    </div>
  );
}

export default AboutUs;