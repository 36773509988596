import React, { Fragment } from 'react';
import './App.css';
import { Outlet } from 'react-router-dom';
import { AppBar, Avatar, Container, Link, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import logo from './Postperson.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © Postperson Studio '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Logo(props) {
  return (
    <Fragment>
        <Avatar src={logo} alt="Postperson studio logo" variant="rounded" />
        <Typography component={RouterLink} variant='h6' noWrap to='/' color='text.primary' sx={{ flexGrow: 1 }} {...props}>
          Postperson Studio
        </Typography>
    </Fragment>
  );
}

function App() {
  return (
    <Fragment>
      <AppBar position='static' color='default' elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar sx={{flexWrap: 'wrap'}}>
          <Logo />
          <nav>
            <Link component={RouterLink} to='/about-us' variant='button' color='text.primary' sx={{my: 0, mx: 1.5}}>
              About Us
            </Link>
            <Link component={RouterLink} to='/games' variant='button' color='text.primary' sx={{my: 0, mx: 1.5}}>
              Games
            </Link>
          </nav>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Outlet />
      </Container>
      {/* Footer */}
      <Container maxWidth="md" component="footer" sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </Fragment>
  );
}

export default App;
