import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#264580',
    },
    secondary: {
      main: '#d69336',
    },
    background: {
      default: '#232425',
    },
  },
});

export default theme;